.navbar{
    height: 50px;
    border-bottom: 0.5px solid #d3d3d3d1;
    // background-color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: gray;
    
    .wrapper{
        width: 100%;
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;

        .search{
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;

            input{
                border: none;
                outline: none;
                background-color: transparent;

                &::placeholder{
                    font-size: 12px;
                }
            }

        }
        .items{
            display: flex;
            align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 20px;

                .icon{

                }

                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
        }
    }


}