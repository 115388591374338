    .productsPage {
        display: flex;
        width: 100%;

        .singleProductContainer {
            flex: 6;

            .top {
                padding: 20px;
                display: flex;
                gap: 20px;

                .left {

                    .button {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 15px;
                        button {

                            text-decoration: none;
                            color: green;
                            font-size: 16px;
                            font-weight: 400;
                            border: 1px solid green;
                            padding: 5px;
                            border-radius: 5px;
                            cursor: pointer;
                            background: transparent;

                        }
                        button:hover{
                            background-color: #008080;
                            color: white;
                        }
                    }

                    flex: 1;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    padding: 20px;
                    position: relative;
                }

                .right {
                    flex: 1;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    padding: 20px;
                    position: relative;
                    // height: 80vh;
                    display: flex;
                    flex-direction: column;

                    .deleteButton{
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 5px;
                        font-size: 12px;
                        color: #ff0000;
                        background-color: rgba(117, 81, 248, 0.0941176471);
                        cursor: pointer;
                        border-radius: 0px 0px 0px 5px;
                    }
                    hr {
                        margin: 10px 0px;
                        color: #dacccc53;
                        opacity: .5;
                    }

                    .images {
                        // flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        background-color: white;
                        width: 650px;
                        border-radius: 10px;
                        padding: 10px 20px;
                        gap: 20px;
                        margin-top: 15px;

                        .grid {
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            gap: 5px 0px;
                            align-items: center;

                            img.selected {
                                border: 1px solid #dacccc;
                                border-radius: 5px;
                            }

                            img {
                                object-fit: contain;
                                height: 32px;
                                width: 32px;
                                cursor: pointer
                            }

                            .upload-image {
                                position: relative;
                                display: inline-block;
                            }

                            .upload-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.6);
                                /* Adjust the opacity as needed */
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                opacity: 1;
                                transition: opacity 0.3s ease;
                                color: #ffffff;

                                .upload-btn {
                                    // font-size: 10px;
                                    padding: 5px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .mainImage {
                            flex: 4;

                            align-items: center;
                            justify-content: center;
                            /* text-align: center; */
                            display: flex;

                            img {
                                object-fit: contain;
                                width: 120px;
                                border-radius: 10px;
                            }
                        }

                        .action {
                            flex: 1;
                            padding: 20px 0px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;

                            .icon {
                                cursor: pointer;
                                color: cadetblue;
                            }
                        }


                    }

                    .details {
                        flex: 3;
                        margin-top: 30px;

                        .form {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 30px;
                            justify-content: space-around;

                            .formInput {
                                width: 40%;

                                label {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    color: darkgrey;

                                    .icon {
                                        cursor: pointer;
                                    }
                                }

                                input,
                                textarea {
                                    width: 100%;
                                    padding: 5px;
                                    border: none;
                                    border-bottom: 1px solid gray;
                                    outline: none;
                                }

                                textarea {
                                    width: 180px;
                                    height: 70px;
                                }

                                input:focus {
                                    border-bottom: 1px solid gray;
                                    //    border: none;
                                }
                            }

                            button {
                                width: 150px;
                                padding: 10px;
                                border: none;
                                background-color: teal;
                                color: white;
                                font-weight: bold;
                                cursor: pointer;
                                margin-top: 10px;
                                width: 70%;
                            }
                        }

                    }

                }

                .products {}

            }
        }
    }