.templatePage {
    .templatePage__tabsContainer {
        .templatePage__tabContent {
            display: flex;
            justify-content: space-between;
            width: 95%;
            padding: 10px;

            .formContainer {
                padding: 10px;
                width: 68%;

                &__label {
                    display: block;
                    margin-bottom: 5px;
                    color: darkgrey;
                }

                &__input,
                &__select,
                &__textarea {
                    width: calc(100% - 18px);
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 15px;
                    resize: vertical;
                }

                &__textarea {
                    min-height: 100px;
                }

                &__select {
                    width: 100%;
                }

                .action {
                    margin-bottom: 10px;
                    position: relative;

                    .formContainer__radioButtons {
                        display: flex;
                        justify-content: space-between;

                        .radioButton {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;

                            &:not(:last-child) {
                                margin-right: 20px; // Add spacing between radio buttons
                            }

                            input[type='radio'] {
                                margin-right: 5px;
                            }

                            label {
                                color: darkgrey;
                                cursor: pointer;
                            }
                        }
                    }

                    .formContainer__callToAction {
                        // margin-top: 20px;
                        display: flex;
                        position: relative;
                        justify-content: space-between;
                        align-items: center;

                        button {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 4px;
                            background-color: transparent;
                            border: none;
                            color: red;
                            font-weight: bold;
                            cursor: pointer;

                        }

                        .formContainer__inputGroup {
                            display: inline-block;
                            margin-right: 20px;
                            vertical-align: top;
                        }

                        .formContainer__label {
                            display: block;
                            margin-bottom: 5px;
                            color: darkgrey;
                        }

                        .formContainer__select,
                        .formContainer__input {
                            width: 150px;
                            padding: 8px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            margin-bottom: 15px;
                            resize: vertical;
                        }

                        .formContainer__select {
                            width: 150px;
                        }
                    }

                    .formContainer__quickReply {

                        button {
                            position: absolute;

                            right: 0;
                            padding: 4px;
                            background-color: transparent;
                            border: none;
                            color: red;
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }
            }

            .previewButton {
                padding: 10px 20px;
                border: none;
                background-color: teal;
                color: white;
                font-weight: bold;
                cursor: pointer;
                border-radius: 4px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #008080;
                }
            }

            .formContainer__fileInput {
                margin-top: 10px;
                display: flex;
                align-items: center;

                .formContainer__label {
                    margin-right: 10px;
                    color: darkgrey;
                }

                input[type='file'] {
                    display: none;
                }

                .customFileInput {
                    display: inline-block;
                    padding: 8px 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #f9f9f9;
                    color: #333;
                    font-size: 14px;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #e0e0e0;
                    }
                }
            }

            .previewContainer {
                margin-top: 20px;
                padding: 15px;
                width: 30%;
                position: relative;
                // height: 70vh;

                .left {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: rgba(240, 248, 255, 0.8);
                    border: 4px solid white;
                    border-radius: 5px;
                    padding: 5px;
                    width: 80%;
                    margin-top: 10px;

                    button {
                        margin-bottom: 10px; // Add margin bottom for gap between buttons
                        background-color: #d5d5d5;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;

                        svg{
                            font-size: 12px;
                        }
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
                    background-size: cover;
                    z-index: -1;
                    border-radius: 5px;
                }

                &__title {
                    margin-top: 0;
                    margin-bottom: 10px;
                }

                &__previewImage {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                    border-radius: 4px;
                    margin-bottom: 10px;
                }

                &__previewVideo {
                    width: 100%;
                    height: 0;
                    padding-top: 100%;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 10px;

                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                &__fileInfo {
                    margin-bottom: 10px;
                }

                &__info {
                    margin-bottom: 5px;
                }
            }
        }
    }
}