.single {
    display: flex;
    width: 100%;
    .singleOrderContainer {
      flex: 6;
  
      // .top {
      //   padding: 20px;
      //   display: flex;
      //   gap: 20px;
  
      //   .left {
      //     flex: 1;
      //     -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      //     box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      //     padding: 20px;
      //     position: relative;
  
      //     .editButton {
      //       position: absolute;
      //       top: 0;
      //       right: 0;
      //       padding: 5px;
      //       font-size: 12px;
      //       color: #7451f8;
      //       background-color: #7551f818;
      //       cursor: pointer;
      //       border-radius: 0px 0px 0px 5px;
      //     }
  
      //     .item {
      //       display: flex;
      //       gap: 20px;
  
      //       .itemImg {
      //         width: 100px;
      //         height: 100px;
      //         border-radius: 50%;
      //         object-fit: cover;
      //       }
  
      //       .details {
      //         .itemTitle {
      //           margin-bottom: 10px;
      //           color: #555;
      //         }
  
      //         .detailItem {
      //           margin-bottom: 10px;
      //           font-size: 14px;
  
      //           .itemKey {
      //             font-weight: bold;
      //             color: gray;
      //             margin-right: 5px;
      //           }
  
      //           .itemValue {
      //             font-weight: 300;
      //           }
      //         }
      //       }
      //     }
      //   }
  
      //   .right {
      //     flex: 2;
      //   }
      // }
      .top {
        padding: 20px;
        display: flex;
        gap: 20px;

        .left {
            flex: 1;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;

            .editButton {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px;
                font-size: 12px;
                color: #7451f8;
                background-color: #7551f818;
                cursor: pointer;
                border-radius: 0px 0px 0px 5px;
            }

            .item {
                display: flex;
                gap: 20px;
                align-items: center;

                .itemImg {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }


            }

            .accounts {
                display: flex;
                gap: 20px;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                .account {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .image {

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                        cursor: pointer;

                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    .label {
                        font-size: 12px;
                        color: gray;
                        margin-top: 5px;


                        &.Active {
                            color: rgb(21, 184, 0);

                        }



                    }

                }
            }
        }

        .details {
            .itemTitle {
                margin-bottom: 10px;
                color: #555;
            }

            .detailItem {
                margin-bottom: 10px;
                font-size: 14px;

                .itemKey {
                    font-weight: bold;
                    color: gray;
                    margin-right: 5px;
                }

                .itemValue {
                    font-weight: 300;
                }
            }
        }

        .right {
            flex: 1;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .title {}

            .details {
                flex: 1;

                height: 50%;
                display: flex;
                align-items: end;
                .itemValue {
                    font-weight: 700;
                    color: darkcyan;
                    font-size: 15px;
                }
                .section-1{
                    flex: 1;
                }
                .section-2{
                    flex: 1;
                    text-align: end;
                    margin-right: 20px;
                   

                }
            }

            .products {
                flex: 1;
                // height: 50%;

            }
            .actions{
                margin: 20px 0px;
                .link{
                    text-decoration: none;
                    color: green;
                    font-size: 16px;
                    font-weight: 400;
                    border: 1px solid green;
                    padding: 5px;
                    border-radius: 5px;
                    cursor: pointer;
                    svg{
                        color: red;
                        
                    }
                }
                button{
                      border: 1px solid green;
                      border-radius: 5px;
                      height: 30px;
                      width: 100px;
                      background-color: transparent;
                      cursor: pointer;

                      &:hover{
                        color: white;
                        background-color: rgba(0, 128, 0, 0.571);
                      }
                  }
            }

            h1 {
                margin-bottom: 0;
            }

            .table {
                -webkit-box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);
                -moz-box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);
                box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);
                
                margin-top: 10px;
            }
        }

        .bottom {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 10px 20px;
        }

        .title {
            font-size: 16px;
            color: lightgray;
            margin-bottom: 20px;
        }
    }
  
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 10px 20px;
      }
  
      .title {
        font-size: 16px;
        color: lightgray;
        margin-bottom: 20px;
      }
    }
  }