.singleOrder {
    display: flex;
    width: 100%;

    .singleOrderContainer {
        flex: 6;

        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;

                .editButton {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    font-size: 12px;
                    color: #22bb00;
                    background-color: #7551f818;
                    cursor: pointer;
                    border-radius: 0px 0px 0px 5px;
                }

                .item {
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    .itemImg {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }


                }

                .accounts {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    .account {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .image {

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                            cursor: pointer;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }

                        .label {
                            font-size: 12px;
                            color: gray;
                            margin-top: 5px;


                            &.Active {
                                color: rgb(21, 184, 0);

                            }



                        }

                    }
                }
            }

            .details {
                .itemTitle {
                    margin-bottom: 10px;
                    color: #555;
                }

                .detailItem {
                    margin-bottom: 10px;
                    font-size: 14px;

                    &.status{
                        display: flex;
                        svg{
                            color: green;
                            cursor: pointer;
                        }
                    }

                    .itemKey {
                        font-weight: bold;
                        color: gray;
                        margin-right: 5px;
                    }

                    .itemValue {
                        font-weight: 300;
                    }
                }
            }

            .right {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;

                .title {}

                .details {
                    flex: 1;
                    margin-top: 12px;
                    // height: 50%;
                    display: flex;

                    // align-items: end;
                    .itemValue {
                        font-weight: 700;
                        color: darkcyan;
                        font-size: 15px;
                    }

                    .section-1 {
                        flex: 1;
                    }

                    .section-2 {
                        flex: 1;
                        text-align: end;
                        margin-right: 20px;


                    }
                }

                .products {
                    flex: 1;
                    // height: 50%;

                }

                .actions {
                    margin: 20px 0px;
                    display: flex;

                    justify-content: space-evenly;

                    .link {
                        text-decoration: none;
                        color: green;
                        font-size: 16px;
                        font-weight: 400;
                        border: 1px solid green;
                        padding: 5px;
                        border-radius: 5px;
                        cursor: pointer;

                        svg {
                            color: red;

                        }
                    }

                    button {
                        border: 1px solid green;
                        border-radius: 5px;
                        height: 50px;
                        width: 150px;
                        background-color: transparent;
                        cursor: pointer;

                        &:hover {
                            color: white;
                            background-color: rgba(0, 128, 0, 0.571);
                        }
                    }
                }

                h1 {
                    margin-bottom: 0;
                }

                .table {
                    -webkit-box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);
                    -moz-box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);
                    box-shadow: 0px 0px 22px 2px rgba(222, 222, 222, 1);

                    margin-top: 10px;
                }
            }

            .bottom {
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                margin: 10px 20px;
            }

            .title {
                font-size: 16px;
                color: lightgray;
                margin-bottom: 20px;
            }
        }
        .pickupPanel {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10000; // Make sure it's above other elements
            width: 300px; // Adjust the width as needed
            background-color: #fff;
            padding: 20px;
            box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            display: flex;
            flex-direction: column;
            gap: 20px;

            // Customize the styles as needed
            &__closeButton {
                background-color: transparent;
                border: none;
                cursor: pointer;
                font-size: 16px;
                color: #333;
            }

            &__content {
                // Add any specific styling for the content
            }

            &__buttons {
                // Add any specific styling for the buttons container
            }
        }
    }
}

.loaderbox {
    display: flex;
    // width: 100%;
    // height: 100%;
    align-items: center;
    justify-content: center;
}
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.093); /* Semi-transparent white overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }