.widget {

    display: inline-flex;

    flex: 1;
    padding: 10px;
    justify-content: space-between;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 126px;
    width: 150px;
    margin-left: 20px;
    margin-top: 20px;

    .left,
    .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative; 

        .title {
            font-weight: bold;
            font-size: 10px;
            color: darkgrey;
        }

        

        .counter {
            font-size: 28px;
            font-weight: 300;
            // font-weight: bold;
            color: #727272;
        }

        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
            cursor: pointer;

        }

        .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
                color: green;
            }

            &.negative {
                color: red;
            }
        }

        .icon {
            font-size: 18px;
            padding: 5px;
            background-color: rgba(186, 6, 183, 0.47);
            border-radius: 5px;
            align-self: flex-end;
        }
    }

    .left {
        align-items: start;
        .sub-title {
            position: absolute;
            top: 80px;
            left: 3px;
            color: darkgrey;
            font-size: 10px;

        }
        .counter{
            font-weight: 400;
        }
    }

    .right {
        align-items: end;
        .sub-title {
            position: absolute;
            top: 76px;
            
            color: darkgrey;
            font-size: 10px;
            left: 31px;
        }
        .counter{
            font-size: 18px;
        }
    }
}